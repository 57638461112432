body {  margin: 0; font-family: var(--bs-font-sans-serif);font-size: 1rem;font-weight: 400; line-height: 1.5; color: #212529; background-color: #fff; -webkit-text-size-adjust: 100%;-webkit-tap-highlight-color: transparent;    overflow-y: scroll !important;}
[type=button], [type=reset], [type=submit], button {-webkit-appearance: button;}
[role=button] {cursor: pointer;}
img, svg {vertical-align: middle;}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {cursor: pointer;}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {width: 100%;padding-right: var(--bs-gutter-x,.75rem); padding-left: var(--bs-gutter-x,.75rem); margin-right: auto;margin-left: auto;}
.table-responsive { overflow-x: auto; -webkit-overflow-scrolling: touch;}
.table { --bs-table-bg: transparent;--bs-table-striped-color: #212529; --bs-table-striped-bg: rgba(0,0,0,0.05); --bs-table-active-color: #212529; --bs-table-active-bg: rgba(0,0,0,0.1); --bs-table-hover-color: #212529;--bs-table-hover-bg: rgba(0,0,0,0.075); width: 100%;
margin-bottom: 1rem;color: #212529;vertical-align: top;border-color: #dee2e6;}
button, input, optgroup, select, textarea { margin: 0;font-family: inherit;font-size: inherit;line-height: inherit;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {margin-top: 0; margin-bottom: 0.5rem;font-weight: 500;line-height: 1.2;}
label {display: inline-block;}
.header-desktop .container {    max-width: 1050px;}
.row>* { flex-shrink: 0; width: 100%;max-width: 100%; padding-right: calc(var(--bs-gutter-x)/ 2);padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y);}
.col-12 {-webkit-flex: 0 0 auto; flex: 0 0 auto;width: 100%;}
.col-2 { flex: 0 0 auto; width: 16.6666666667%;}
.col-4{flex: 0 0 auto;  width: 33.3333333333%;}
.col-3 { flex: 0 0 auto; width: 25%;}
.col-5 {flex: 0 0 auto; width: 41.6666666667%;}
.pe-5 {padding-right: 3rem!important;}
.pe-0 {padding-right:0!important;}
.pb-0 {padding-bottom: 0!important;}
.pe-1 { padding-right: .25rem!important;}
.pt-3 {padding-top: 1rem!important;}
.col-7 { flex: 0 0 auto; width: 58.3333333333%;}
.col-6 { flex: 0 0 auto; width: 50%;}
.col-8 {flex: 0 0 auto;  width: 66.6666666667%;}
.col-4 {flex: 0 0 auto;width: 33.3333333333%;}
.col-9 {  flex: 0 0 auto; width: 75%;}
.pb-5 {padding-bottom: 3rem!important;}
.pt-5 { padding-top: 3rem!important;}
.d-block {display: block!important;}
.d-inline-block {display: inline-block!important;}
.w-100 {width: 100% !important;}
.ps-4{padding-left: 1.5rem!important;}
.pb-4 {padding-bottom: 1.5rem!important;}
.pt-4 { padding-top: 1.5rem!important;}
.d-none { display: none!important;}
.p-0{ padding:0 !important;}
.align-items-center { align-items: center!important;}
.align-items-end {-webkit-align-items: flex-end!important;align-items: flex-end!important;}
.row {--bs-gutter-x: 1.5rem;--bs-gutter-y: 0;display: flex;flex-wrap: wrap; margin-top: calc(var(--bs-gutter-y) * -1); margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2);}
.col { flex: 1 0 0%;} 
.pb-1 {padding-bottom: .25rem!important;}
.pt-2 { padding-top: .5rem!important;} 
.px-5 {  padding-right: 3rem!important;  padding-left: 3rem!important;}
.float-start { float: left!important;}
.float-end {float: right!important;}
.h-100 { height: 100%!important;}
.col-10 {    -webkit-flex: 0 0 auto; flex: 0 0 auto; width: 83.3333333333%;}
.col-11 {-webkit-flex: 0 0 auto; flex: 0 0 auto; width: 91.6666666667%;}
.mt-0 { margin-top: 0!important;}
  .container{    width: 100%;padding-right: var(--bs-gutter-x,.75rem);padding-left: var(--bs-gutter-x,.75rem);
  margin-right: auto; margin-left: auto;}
  .container {    max-width: 1050px;}
  sub, sup {position: relative;font-size: .75em;line-height: 0;vertical-align: baseline;}
  .btn-close { -webkit-box-sizing: content-box;-moz-box-sizing: content-box; box-sizing: content-box;width: 1em;height: 1em;padding: 0.25em; color: #000;background: transparent url(/images/close-btn-svg.inline.svg) 50%/1em auto no-repeat; border: 0; border-radius: 0.25rem; opacity: .5;}
sup {top: -0.5em;} 
.invalid-feedback {display: none;width: 100%; margin-top: 0.25rem;font-size:12px;color: #dc3545;font-family: 'Open Sans';}
.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {display: block;}
.position-relative {position: relative!important;}
[hidden] {display: none!important;}
@media (min-width: 768px){
    .pe-md-0 {padding-right: 0!important;}
    .mb-md-4 { margin-bottom: 1.5rem!important;}
    .col-md-9 {    -webkit-flex: 0 0 auto;flex: 0 0 auto; width: 75%;}
    .pe-md-5 { padding-right: 3rem!important;}
    .col-md-5 { flex: 0 0 auto;width: 41.6666666667%;}
    .col-md-2 {flex: 0 0 auto;width: 16.6666666667%;}
    .col-md-6 { -webkit-flex: 0 0 auto; flex: 0 0 auto; width: 50%;}
    .col-md-4 {flex: 0 0 auto; width: 33.3333333333%;}
    .col-md-8 {-webkit-flex: 0 0 auto;flex: 0 0 auto; width: 66.6666666667%;}
    .col-md-7 {-webkit-flex: 0 0 auto; flex: 0 0 auto; width: 58.3333333333%;}
    .col-md-12 { -webkit-flex: 0 0 auto;flex: 0 0 auto;width: 100%;}
    .col-md-3 {-webkit-flex: 0 0 auto;flex: 0 0 auto;width: 25%;}
    .ps-md-0 {padding-left: 0!important;}
  .ps-md-4 {padding-left: 1.5rem!important;}
  .pt-md-5 {padding-top: 3rem!important;}
  .pe-md-4 { padding-right: 1.5rem!important; }
  .ps-md-4 { padding-left: 1.5rem!important;}
  .pe-md-0 {padding-right: 0!important;}
  .pe-md-5 {padding-right: 3rem!important;}
    }
    .spin_main{ display: flex; align-items: center;   height: 100%; width: 700px; overflow: hidden;   background:url('/images/spinthewheel/pos_spinthewheel_3.jpg') ; position: relative;    z-index: 9;    background-size: cover;
      background-position: center;}
    span.spin_button { font-family: Bell MT !important; position: absolute;    top: 51%; left: 48.4%; transform: translate(-50%, -50%);font-size: 17px; text-align: center; width: 95px;height: 95px;background: #fff;border-radius: 50%; color: #eb2429;display: flex;justify-content: center; align-items: center; font-weight: 600;pointer-events: none;
      margin-left: -230px;}
      span.pos_spin_button { font-family: Bell MT !important; position: absolute;    top: 53.9%; left: 72.3%; transform: translate(-50%, -50%);font-size: 17px; text-align: center; width: 66px;height: 66px;background: #fff;border-radius: 50%; color: #eb2429;display: flex;justify-content: center; align-items: center; font-weight: 600;pointer-events: none;
        margin-left: -231px;}
      .cancel_btn1{ position:absolute; background: white; text-align: center; display: flex; align-items: center; right: 15px;top: 15px; width: 30px; height: 30px;border-radius: 50%; text-align: center;}
      .cancel_btn1 img{ margin: 0 auto;}
    .spin_main_code{     height: 100%;  background:url(/images/spin-the-wheel_backgroun4.jpg) #901215;   background-size: cover;
      background-position: center; height: 100%; width: 620px;text-align: center; display: flex;flex-direction: column; justify-content: center; padding: 30px 50px;}
    .spin_main_code h2{     font-weight: 600;font-size: 40px; padding-bottom: 20px;    color: #ffffff; }
    .spin_main_code h2 span{ display: block;font-size: 28px;color: #ffffff; }
    .spin_main_code h2 b{    color: #313131; padding-top: 10px;}
    .spin_main_code h3{margin-top: 32px; color: #ffffff; font-size: 16px;  padding-bottom: 30px; text-transform: uppercase; font-family: 'Open Sans';}
    .spin_main_code p{     color: #313131;}
    .spin_main_code h3 b{ font-size: 24px;font-weight: 600;    color: #000000; margin-left: 10px; 
      background: #e7af26;
      color: white;
      font-weight: 600;
      padding: 6px 8px;
      display: inline-block;
      border-radius: 5px;
      font-size: 18px;}
    .spin_main_code .btn-shopping { cursor: pointer; padding: 14px; display: inline-block; width: 225px; background-color: #fff; margin: 0 auto; font-size: 14px;font-weight: 600; font-family: 'Open Sans';}
    div#wheel {height: 590px;    margin-left: -190px;     overflow-y: hidden;}
    div#posWheel {height: 370px; margin-left: 210px; overflow: hidden;}
    .spin_main h2{ color: #eb2429;   top: 0;width: 100%;font-size:34px; font-weight: 600; padding-bottom:12px; line-height: 1.2;}
    .spin_main_content h3{ color: black; text-align: center; text-transform: uppercase; top: 0;width: 100%;font-size:18px; font-weight: 600; padding-bottom: 12px; line-height: 1.2;}
      .spin_main_code h2 b{ display: block;    font-size: 22px; font-weight: 600;    padding-top: 10px;}
      .spin_main_content{ top: 4rem; position: absolute; right: 0;  width: 290px; padding-right: 20px;  text-align: center;}
      .spin_main_content p{  margin-bottom:15px;line-height: 1.7; font-size: 12px; text-align: center; color: black; text-transform: uppercase; }
      .spin_main_content input { border: 0; width: 80%; padding: 4px 6px; font-size: 12px;    border: 1px solid rgb(109 161 197); opacity: 1; background: #fff;}
      .spin_main_content button { text-transform: uppercase; width: 30%; margin-top: 16px;padding: 4px;background: #e7af26;  color: #fff;}
      .claim-btn{ text-transform: uppercase;  margin-top: 6px;padding: 6px;background: #164b71;  color: #fff; width: 100% !important; white-space: nowrap; }
      .spin_main_code h2 div {     color: #ffffff; font-size: 22px; font-weight: 400; margin-top: 20px;}
      .spin_main_code h2 div p{ font-size: 13px;line-height: 1.7; padding-top: 10px; margin-bottom: 10px;letter-spacing: 0.25px;}
       .spin_main_row:before {position: fixed; width: 100%;height: 100%; content: "";} 
       .spin_main_code h2 img { margin: 0 auto;}
      @media (max-width: 767px){
        .spin_main_row:before{ display: none;}
      .spin_main_code{padding: 30px 15px;    width:100%;    justify-content: normal;padding-top: 70px;}
      .spin_main_code h2 { font-size: 28px;}
      .spin_main_code h2 b{font-size: 20px;}
      .spin_main_code h3 {font-size: 14px;} 
      .spin_main_code h3 b {font-size: 16px;}
      div#wheel { z-index: -1; height: 440px; margin-left: -220px;}
      div#posWheel {height: 380px; margin-left: -125px; overflow: hidden;}
    .spin_main h2{  padding-top:14px;padding-bottom:6px; font-size: 28px;}
    .spin_main{width: 350px;    background-image: none;align-items: flex-end;    min-height:720px;height: 100%;}
    span.pos_spin_button { font-family: Bell MT !important; position: absolute;    top: 52.4%; left: 408px; transform: translate(-50%, -50%);font-size: 17px; text-align: center; width: 60px;height: 60px;background: #fff;border-radius: 50%; color: #eb2429;display: flex;justify-content: center; align-items: center; font-weight: 600;pointer-events: none;
      margin-left: -233px;}
    span.spin_button{font-family: Bell MT !important; top: auto;left: 32px;bottom:93px;transform: none;  margin: 0;}
    .spin_main_row{background-image: radial-gradient(#b9e2f8, #5294d0);    background: url('/images/spinthewheel/pos_spinthewheel_3.jpg');
      background-size: cover;  background-position: center;}
    .spin_main_content{  z-index:1;  top: 0; padding: 0 15px; z-index: 99;   right: auto;  width: 100%;text-align: center; 
      left: 50%; position: fixed;transform: translate(-50%, 0);width: 300px;}
    .spin_main_row{ max-width: 100%;    min-height:720px;height: 100%;}
    .cancel_btn1{ z-index: 9999; position: fixed;}
    .claim-btn{    top: 20%;    right: auto;  left: 80px;}
    .spin_main_content p{ width: 80%;    margin: 0 auto 12px;}
    .spin_main_content h3{padding-bottom: 6px; font-size: 18px;}
    .spin_main h2 img { position: relative; z-index: -1; width: 240px; height: auto; margin: 0 auto;}
    .spin_main_content form { position: relative;}
    .spin_main_content button{    position: absolute;right: 0; top: 0;width: auto;padding: 3px 20px; margin-top: 0;}
    .spin_main_content button.claim-btn { position: relative;width: 100%; left: auto; top: auto;}
    .spin_main_code h2 img {  margin: 0 auto 0px;}
    }
    .spin_main_row{ width: 100%; position: relative;     height: 100%;}
    .spin-main{ height: 100%;}
    .spin_main_code h3 span { cursor: pointer; font-size: 12px;  background: #fff; color: #313131; font-weight: 600; padding: 6px 8px;display: inline-block; 
      border-radius: 5px; margin-left: 10px;  line-height: 1;position: relative;}
      .spin_main_code h3 span:hover{
        color: #C4601D;
      }
      .spin_main_content input:disabled { opacity: .6;}
       