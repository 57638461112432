.new-address-sec3 { display: inline-block; width: 100%; padding-top:50px; margin-bottom: 20px;}
		.new-address-sec3 li a,.new-address-sec3 button{ display: block; width: 100%; padding: 13px 0px;font-size: 12px; font-weight: 600; color:#C4601D;text-transform: uppercase;letter-spacing: 1.25px;}
		.new-address-sec3 ul{ margin: 0;box-shadow: 0px 0px 16px rgba(82,53,40,0.16); border-radius: 5px;overflow: hidden;}
		.new-address-sec3 ul li{ padding: 0;}
		.new-address-sec3 ul li a span{ margin: 0 auto; display: table;}
		.new-address-sec3 ul li:last-child a,.new-address-sec3 button{ background-color: #C4601D; color: #fff;} 
		.edit-information { width: 100%; display:inline-block; margin-top: 30px;}	
		.edit-information h2 { font-size: 28px; letter-spacing: 0.15px; color: #313131;   padding-bottom: 20px;}
		.contact-form { width: 100%;display: table;}	
		.edit-information .contact-form label{    padding-bottom: 10px;color:#C4601D;font-size: 12px; display: block; }
		.contact-form input{ border: 0; border-bottom: 1px solid #f0ddd1;font-size: 14px; width: 100%;padding-bottom: 6px; font-weight: 600;padding:5px 0;}
	.contact-form .input-colm { position: relative;width: 100%; display: table; padding-bottom: 18px;}
	.edit-information .input-colm span {float: right; font-size: 12px;  text-transform: uppercase;  letter-spacing: 1.25px;  color: #828282;  padding-top: 4px;}	
		.input-registered	{ position: relative;}
		.input-registered abbr{position: absolute; bottom: 5px;  right: 0;} 
		.edit-information .input-colm span a{    color: #828282;}
		.not-verfied img{ width: 22px;    vertical-align: top;}
		.not-verfied {letter-spacing: 0.5px; font-size: 13px;}
		.not-verfied a{letter-spacing: 1.25px;text-transform: uppercase;color:#C4601D; font-weight: 600;}
		.edit-information .contact-form label sup{color: #ff2200;}
		.input-registered abbr img {width: 24px;}
button{ border: 0;}
.input_colm {   display: inline-block; width: 100%;    padding-bottom: 18px;}
.input_colm2 label {	width: 100%;display: inline-block;}	
.input_colm2 ul { width: 28%;padding-right: 10px;}
.contact-form .input_colm2 input{ width:100%;   padding:5px 0; }
.contact-form .input_colm2 label {width: 100%;display: inline-block;}
.input_colm2 ul li{ padding: 5px 0;border-bottom: 1px solid #f0ddd1;letter-spacing: 0.5px;   
	 color: rgb(49 49 49 / 80%);}
	 .input_colm2{ position: relative; display: flex; width: 100%;}
.input-colm input:-webkit-autofill { -webkit-box-shadow: 0 0 0px 1000px white inset;box-shadow: 0 0 0px 1000px white inset;}


	 @media (min-width: 992px){
		.new-address-sec3 li a, .new-address-sec3 button{    padding: 15px 0px;    border: 1px solid #c4601d;
			border-radius: 5px;}	 
		.new-address-sec3 ul{box-shadow: inherit;border-radius: 0;}
		.new-address-sec3{    width: 500px; padding-top: 20px;}
		.edit-information h2 { font-weight: 400;font-size: 40px; position: relative;display: flex;
			flex-direction: column;align-items: center;    padding-bottom: 50px;}
		.edit-information h2:before{ content: ""; width: 186px; height: 56px; display: block; 
			background-image: url(/images/motif1.inline.svg);}
	 }